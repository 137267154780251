import Member from "@/classes/Member.js"

class ChatMessage {
	constructor(d = {}) {
		this.ts = new Date(d.ts);
		this.msg = d.msg;
		this.member = (d.member instanceof Member ? d.member : new Member(d.member));
	}

	getTime() {
		return ("0"+this.ts.getHours()).substr(-2)+":"+("0"+this.ts.getMinutes()).substr(-2);
	}
}

export default ChatMessage;

