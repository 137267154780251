import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
	{
		path: '/',
		component: HomeView
	}, {
		path: '/ranking',
		component: () => import('../views/RankingView.vue'),
	}, {
		path: '/members',
		component: () => import('../views/MembersView.vue'),
	}, {
		path: '/buddies',
		component: () => import('../views/BuddiesView.vue'),
	}, {
		path: '/chat',
		component: () => import('../views/ChatView.vue'),
	}, {
		path: '/tables',
		component: () => import('../views/TablesView.vue'),
	}, {
		path: '/table-create',
		component: () => import('../views/TableCreate.vue'),
	}, {
		path: '/table',
		component: () => import('../views/TableView.vue'),
		children: [
			{
				path: '',
				component: () => import("../views/Table/PlayView.vue"),
			}, {
				path: 'chat',
				component: () => import("../views/Table/ChatView.vue"),
			}, {
				path: 'players',
				component: () => import("../views/Table/MembersView.vue"),
			}, {
				path: 'report',
				component: () => import("../views/Table/ReportView.vue"),
			}, {
				path: 'leave',
				component: () => import("../views/Table/LeaveView.vue"),
			}
		],
	}, {
		path: '/settings',
		component: () => import('../views/SettingsView.vue'),
	}, {
		path: '/report/:id_match',
		component: () => import('../views/ReportView.vue'),
	}, {
		path: '/profile/:id_member',
		component: () => import("@/views/ProfileView.vue"),
		children: [
			{
				path: '',
				component: () => import("@/views/Profile/OverviewView.vue"),
			}, {
				path: 'games',
				component: () => import("@/views/Profile/GamesView.vue"),
			}, {
				path: 'buddies',
				component: () => import("@/views/Profile/BuddiesView.vue"),
			}
		],
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
