import { reactive } from "vue"
import { Loc, LocM } from "@/Loc.js"

import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

import Member from "@/classes/Member.js"

var Store = reactive({

	navDrawer: false,
	navDouble: false,
	notifications: reactive([]),
	chatMessages: [],
	firebase: null,
	firebaseToken: null,

	member: null, // Giocatore corrente
	members: new Map(), // Altri giocatori online
	tables: new Map(), // Tavoli aperti
	table: null, // Tavolo corrente
	profile: null, // Profilo visualizzato

	settings: null,
	l: null,
	langs: { it: "Italiano", en: "English" },

	apiEndpoint: process.env.VUE_APP_ENDPOINT,
	avatarUrl: "https://www.burraco2.com/avatar.php?u=",

	connection: "none", // none|online|offline

	seats2: [
		{
			name: "north",
			label: "Nord",
			icon: "mdi mdi-circle-slice-2 mdi-rotate-315",
			color: "#e20800", // red
		}, {
			name: "south",
			label: "Sud",
			icon: "mdi mdi-circle-slice-2 mdi-rotate-135",
			color: "#0057ae", // blue
		}
	],

	seats4: [
		{
			name: "north",
			label: "Nord",
			icon: "mdi mdi-circle-slice-2 mdi-rotate-315",
			color: "#e20800", // red
		}, {
			name: "east",
			label: "Est",
			icon: "mdi mdi-circle-slice-2 mdi-rotate-45",
			color: "#0057ae", // blue
		}, {
			name: "south",
			label: "Sud",
			icon: "mdi mdi-circle-slice-2 mdi-rotate-135",
			color: "#e20800", // red
		}, {
			name: "west",
			label: "Ovest",
			icon: "mdi mdi-circle-slice-2 mdi-rotate-225",
			color: "#0057ae", // blue
		}
	],

	pairs2: [
		{
			idx: 0,
			seats: [0],
			color: "#e20800", // red
			colorClass: "playcard-red",
		}, {
			idx: 1,
			seats: [1],
			color: "#0057ae", // blue
			colorClass: "playcard-blue",
		}
	],

	pairs4: [
		{
			idx: 0,
			seats: [0, 2],
			color: "#e20800", // red
			colorClass: "playcard-red",
		}, {
			idx: 1,
			seats: [1, 3],
			color: "#0057ae", // blue
			colorClass: "playcard-blue",
		}
	],

	init() {
		this.settings = this.loadSettings();
		LocM(this.settings.lang);

		this.firebase = initializeApp({
			apiKey: "AIzaSyAxRM-y3HyGk-x8a4QfY4FWzClohNHI20U",
			authDomain: "burraco-400713.firebaseapp.com",
			projectId: "burraco-400713",
			storageBucket: "burraco-400713.appspot.com",
			messagingSenderId: "201907786403",
			appId: "1:201907786403:web:0e3748d3427d2372f46516"
		});
	},

	async getCall(path, method = "GET") {

		let res = await fetch(this.apiEndpoint + path, {
			method: method,
			credentials: "include",
		});
		let json = await res.json();
		if (json.error) {
			if (json.response.errmsg === "not-logged-in") {
				throw new Error("not-logged-in");
			} else {
				this.showError(json.errmsg);
			}
		}
		return json;
	},

	async putCall(path) {
		return this.getCall(path, "PUT");
	},

	async deleteCall(path) {
		return this.getCall(path, "DELETE");
	},

	async postCall(path, form) {

		let res = await fetch(this.apiEndpoint + path, {
			method: "POST",
			credentials: "include",
			body: form,
		});
		let json = await res.json();
		if (json.error) {
			this.showError(json.errmsg);
			return null;
		}
		return json;
	},

	toggleNavDrawer() {
		this.navDrawer = !this.navDrawer;
	},

	showMessage(text, type, dur = "long") {
		this.notifications.push({
			type,
			text,
			time: new Date(Date.now() + (dur === "short" ? 5000 : 15000)),
		});
	},

	showNotice(text) {
		this.showMessage(text, "notice");
	},

	showWarning(text) {
		this.showMessage(text, "warn");
	},

	showError(text) {
		this.showMessage(text, "error");
	},

	showPlayNotice(text) {
		this.showMessage(text, "play-notice", "short");
	},

	showPlayWarning(text) {
		this.showMessage(text, "play-warn");
	},

	removeMessage(time) {
		this.notifications = this.notifications.filter(a => a.time != time);
	},

	loadSettings() {
		let settings = localStorage.getItem("b2-settings");
		return Object.assign({ lang: "it", notification: null }, (settings ? JSON.parse(settings) : {}));
	},

	saveSettings() {
		localStorage.setItem("b2-settings", JSON.stringify(this.settings));
	},

	async addBuddy(member) {
		await this.putCall('/user/buddy/'+member.id_member);
		this.member.buddies[member.id_member] = new Member(member);
	},

	async delBuddy(member) {
		await this.deleteCall('/user/buddy/'+member.id_member);
		delete this.member.buddies[member.id_member];
	},

	setLang(l) {
		this.settings.lang = l;
		LocM(this.settings.lang);
		this.saveSettings();
	},

	gettext(s) {
		if (!this.l[s]) return s;

		s = this.l[s];
		for (let i = 1; i < arguments.length; i++) {
			s = s.replace("%"+i, arguments[i]);
		}
		return s;
	},

	cron() {
		// Remove old messages
		let d = new Date();
		this.notifications = this.notifications.filter(a => a.time > d);

		// Current Table timeout
		if (this.table && this.table.timeout && this.table.current == this.member.tableSeat) {
			--this.table.timeout;
			if (this.table.timeout == 60) this.showPlayWarning(Loc.table_hurry);
		}
	},

	formatDateHour(d) {
		let m = d.match(/^(\d+)-(\d+)-(\d+)T(\d+):(\d+)/);
		return `${m[3]}/${m[2]}/${m[1]} ${m[4]}:${m[5]}`;
	},

	async processFirebaseToken() {
		try {
			let registration = await navigator.serviceWorker.getRegistration();

			const messaging = getMessaging(this.firebase);

			this.firebaseToken = await getToken(messaging, {
				vapidKey: 'BCShy2lvxwhrNYA-Ktjm03UWHfZ7Hx-3H0hvB6t-d04hAUCvnCF1kMtKD_Vkx21NEyDpoQaj67cIODgLRrwU5dE',
				serviceWorkerRegistration: registration,
			});

			let f = new FormData();
			f.append("token", this.firebaseToken);
			await this.postCall("/user/firebase", f);

			return true;

		} catch (e) {
			console.error(e);
			this.showError(Loc._loc_unknown_error);
			return false;
		}
	}

});

Store.init();

setInterval(() => Store.cron(), 1000);

export default Store;
