import Store from "@/Store.js"
import Card from "@/classes/Card.js"
import ChatMessage from "@/classes/ChatMessage.js"

class Table {
	constructor(d = {}) {
		this.uuid = d.uuid;
		this.players = d.players; // Numero di giocatori
		this.runs = d.runs; // Mani
		this.members = d.members.map(a => a ? Store.members.get(a) : null);
		this.seats = Store["seats"+this.players];
		this.pairs = Store["pairs"+this.players];
		this.sortHandType = null; // value|seed
		this.resume = true; // Indica se visualizzare il pulsante di chiusura del report di fine mano, viene resettato col primo tableStatus
		this.updateData(d);
	}

	updateData(d = {}) {
		["status", "pots", "pile", "stock", "hands", "hand", "games", "run", "first", "current", "update", "chat", "stats", "points", "burraco", "timeout", "ending"].forEach(k => {
			if (d[k] !== undefined) this[k] = d[k];
		});
		if (this.pile) this.pile = this.pile.map(a => new Card(a));
		if (this.hand) this.hand = this.hand.map(a => new Card(a));
		this.sortHand();
		if (this.games) {
			this.games.forEach(c => {
				c.forEach(b => {
					b.cards = b.cards.map(a => new Card(a));
				});
			});
		}

		if (this.stats) {
			this.points = [0, 0];
			this.stats.forEach(a => {
				a.pairs.forEach((b, bi) => this.points[bi] += b.points_total);
			});

			if (this.status === "done") {
				if (this.ending === 'timeout' || this.ending === 'leave') {
					this.digest = [this.ending, this.current];
				} else if (this.points[0] === this.points[1]) {
					this.digest = ['draw', null];
				} else if (this.points[0] > this.points[1]) {
					this.digest = ['win', 0];
				} else if (this.points[0] < this.points[1]) {
					this.digest = ['win', 1];
				} else {
					this.digest = null;
				}
			}
		}

		if (this.chat) this.chat = this.chat.map(a => {
			if (a instanceof Array) {
				return new ChatMessage({
					member: Store.members.get(a[0]),
					ts: a[1],
					msg: a[2],
				});
			} else {
				return a;
			}
		});

		if (!this.resume) this.resume = true;
	}

	updateMembers(members) {
		this.members = members.map(a => a ? Store.members.get(a) : null);
	}

	sortHand(type = null) {
		if (type === "none") {
			this.sortHandType = null;
		} else if (type) {
			this.sortHandType = type;
		}

		if (this.sortHandType === "value") {
			this.sortHandByValue();
		} else if (this.sortHandType === "seed") {
			this.sortHandBySeed();
		}
	}

	sortHandByValue() {
		this.hand.sort((a, b) => (a.V === b.V ? b.S.v - a.S.v : b.V - a.V));
	}

	sortHandBySeed() {
		this.hand.sort((a, b) => (a.S.v === b.S.v ? b.V - a.V : a.S.v - b.S.v));
	}

}

export default Table;
