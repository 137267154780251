const localization = {
	en: {
/*
		'error': "{t}Errore{/t}",
		'not supported browser': "{t}burraco2_game_not_supported_browser{/t}",
		'good browsers': "{t}burraco2_game_good_browsers{/t}",

		'invalid session': "{t}burraco2_game_invalid_session{/t}",
		'reload the page': "{t}burraco2_game_reload_the_page{/t}",

		'invalid chat': "{t}burraco2_game_invalid_chat{/t}",
		'cannot post to this chat': "{t}burraco2_game_cannot_post_to_this_chat{/t}",

		'connection closed': "{t}burraco2_game_connection_closed{/t}",
		'connected from other host': "{t}burraco2_game_connected_from_other_host{/t}",
		'disconnected by server': "{t}burraco2_game_disconnected_by_server{/t}",

		'cannot close by dropping jolly': "{t}burraco2_game_cannot_close_by_dropping_jolly{/t}",
		'cannot close by dropping locked': "{t}burraco2_game_cannot_close_by_dropping_locked{/t}",
		'cannot close without any burraco': "{t}burraco2_game_cannot_close_without_any_burraco{/t}",
		'cucu1': "{t}burraco2_game_cucu1{/t}",
		'cucu2': "{t}burraco2_game_cucu2{/t}",
		'double combi': "{t}burraco2_game_double_combi{/t}",
		'duplicate player': "{t}burraco2_game_duplicate_player{/t}",
		'have to take': "{t}burraco2_game_have_to_take{/t}",
		'have to play': "{t}burraco2_game_have_to_play{/t}",
		'locked card': "{t}burraco2_game_locked_card{/t}",
		'must close by dropping': "{t}burraco2_game_must_close_by_dropping{/t}",
		'not a game': "{t}burraco2_game_not_a_game{/t}",
		'not at table': "{t}burraco2_game_not_at_table{/t}",
		'not allowed': "{t}burraco2_game_not_allowed{/t}",
		'not to you': "{t}burraco2_game_not_to_you{/t}",
		'not your cards': "{t}burraco2_game_not_your_cards{/t}",
		'not your game': "{t}burraco2_game_not_your_game{/t}",
		'not your invite': "{t}burraco2_game_not_your_invite{/t}",
		'not your table': "{t}burraco2_game_not_your_table{/t}",
		'player already playing': "{t}burraco2_game_player_already_playing{/t}",
		'player is not online': "{t}burraco2_game_player_is_not_online{/t}",
		'seat already taken': "{t}burraco2_game_seat_already_taken{/t}",
		'self not present': "{t}burraco2_game_self_not_present{/t}",
		'table not exists': "{t}burraco2_game_table_not_exists{/t}",
		'too many waiting tables': "{t}burraco2_game_too_many_waiting_tables{/t}",
		'no connection': "{t}burraco2_game_no_connection{/t}",

		'choose_3_cards_for_new_game': "{t}Devi selezionare almeno 3 carte per aprire un nuovo gioco{/t}",
		'choose_1_card_to_play': "{t}Devi selezionare almeno una carta da giocare{/t}",
		'really_want_to_leave': "{t}Abbandonando la partita perderai punti. Vuoi continuare?{/t}",
		'game_not_started': "{t}La partita non è ancora cominciata{/t}",
		'time_is_running_out': "{t}burraco2_game_timeout{/t}",
		'few_seconds_left': "{t}Ti rimangono solo <b>%1 secondi</b> per completare il tuo turno, pena la sconfitta a tavolino!{/t}",
		'game_%1': "{t}Partita %1{/t}",
		'table_points_%1_%2': "{t}Punti: %1 - %2{/t}",
		'table_round_%1_%2': "{t}Smazzata %1 di %2{/t}",
		'table_time_%1': "{t}Tempo: %1{/t}",
*/
		home: "Home",
		login: "Login",
		register: "Register",
		profile: "Profile",
		logout: "Logout",
		chat: "Chat",
		tables: "Tables",
		table: "Table",
		forum: "Forum",
		forum_bug: "Report a problem",
		about_us: "About us",
		user: "User",
		users: "Users",
		score: "Score",
		players: "Players",
		ranking: "Ranking",
		username: "Username",
		password: "Password",
		wait: "…",
		save: "Save",
		change: "Change",
		create_table: "Create table",
		north: "North",
		east: "East",
		south: "South",
		west: "West",
		cancel: "Cancel",
		leave: "Leave",
		home_title: "Burraco2.com",
		website: "Website",
		online_players: "Players Online",
		settings: "Settings",
		nobody: "Nobody",
		nobody_online: "No other player online",
		close: "Close",

		home_notifications: "Enable game notifications to keep in touch!",
		home_notify: "Notifications",

		register_email: "Your e-mail",
		register_password_confirm: "Password Confirm",
		register_conditions: "By clicking \"Register\" you state that you read and accepted <a href=\"https://www.burraco2.com/en/burraco-online-rules.php\" target=\"_system\">the service terms and conditions</a> and <a href=\"https://www.burraco2.com/en/privacy-policy.php\" target=\"_system\">the personal data processing</a>.",
		register_dem: "I accept the personal data processing for commercial comunications and for other purposes listed in the disclosure.",
		register_success: "We sent you an e-nmail to confirm your registration",

		session_login: "Login",
		session_forgot: "Lost password?",
		session_invalid: "Invalid session, login again please",

		users_one: "1 user online",
		users_many: "%1 users online",
		users_record: "Matches %1 (%3) — Victories %2 (%4)",
		users__only_user: "You're currently the only user online.",

		profile_registration_date: "Registration date",
		profile_last_login: "Last login",
		profile_forum_profile: "Edit full profile",
		profile_settings: "Settings",
		profile_records: "Records",
		profile_matches: "Matches",
		profile_buddies: "Friends",
		profile_name: "Display name",
		profile_language: "Language",
		profile_newsletter: "Send me the newsletter and game updates.",
		profile_dem: "I accept the personal data processing for commercial comunications and for other purposes listed in the disclosure.",
		profile_h_last_matches: "Last matches played",
		profile_h_change_password: "Change password",
		profile_current_password: "Current password",
		profile__match_points: "%1 Points — %2 Victory points",
		profile_no_buddies: "No friends yet",

		create_players: "Players",
		create_players_4: "4 Players",
		create_players_2: "2 Players",
		create_rounds: "Rounds",
		create_sit_down: "Sit Here",
		create_your_self: "[Your self]",
		create_free: "[Free]",
		create_duplicate_player: "The same player sits more than once",
		create_self_not_present: "Choose a seat for yourself",
		create_runs: "Rounds",

		tables_rounds: "Rounds",
		tables__no_tables: "The are no waiting tables. Use the + icon on top right to create one.",

		stats_h_beginning: "All time record",
		stats_h_30days: "30 days record",
		stats_matches: "Matches",
		stats_wins: "Victories",
		stats_losses: "Losses",
		stats_draws: "Draws",
		stats_leaves: "Abadons",
		stats_timeouts: "Time Outs",
		stats_points : "Points",

		digest_timeout: "Timeout",
		digest_leave: "Leave",
		digest_draw: "Draw",
		digest_red: "Victory Red",
		digest_blue: "Victory Blue",

		table_sort_suit: "Suit",
		table_sort_value: "Value",
		table_takepile: "Pile",
		table_takestock: "Draw",
		table_drop: "Discard",
		table_points: "points",
		table_cards: "cards",
		table_games: "Games",
		table_new_game: "New game",
		table_pile: "Pile",
		table_stock: "Stock",
		table_pot: "Pot",
		table_pots: "Pots",
		table_tab_all: "All",
		table_no_table: "No table available",
		table_new_table: "Create table",
		table_options: "Table options",
		table_invite: "Invite friends",
		table_add: "Add",
		table_burraco: "Burraco",

		table_wait: "Waiting for players",
		table_wait__free: "Free seat",
		table_wait__invited: "Waiting confirm",

		table_chat: "Table chat",

		table_status: "Table score",
		table_status_round: "Round %1 of %2",
		table_status_running: "This game is running…",
		table_status_base: "Base",
		table_status_positive: "Positive",
		table_status_negative: "Negative",
		table_status_total: "Total",
		table_result_grandtotal: "Grand Total",
		table_hurry: "Hurry up! One minute left for your move",

		table_users: "Table users",

		table_result: "Score",
		table_result_back_to_table: "Back to Table",
		table_result_leave_table: "Leave Table",
		table_result_wait_for_players: "Waiting for Players",
		table_result_points: "%1 points",

		table_leave: "Leave Table",
		table_leave_advice: "By leaving the table you will lose the match. Proceed?",

		table_thankyou: "Game over, thank you for playing ❤",

		report_round: "Round",
		report_of: "of",
		report_title: "Point report",
		report_points_base: "Base points",
		report_points_positive: "Positive points",
		report_points_game: "Game points",
		report_points_negative: "Negative points",
		report_points: "Total Points",

		log_discard: "%1 has discarded %2%3",
		log_takestock: "%1 has drawn a card",
		log_takepile: "%1 has taken the pile %2",
		log_gamenew: "%1 has create a new game with %2 cards",
		log_gameadd: "%1 has attached %2 cards to a game",
		log_takepot: "%1 ha taken a pot",

		rank_none: "Ranking not available",
		match_detail: "Match detail",
		match_none: "No recent match",

		home_welcome: "Welcome",
		home_goto_tables: "Go to Tables",
		home_goto_chat: "Go to Chat",
		home_your_profile: "Your Profile",

		buddies_none: "There are no friends.<br/>Click on the heart next to a players name to add him/her to your friends.",

		offline: "Connection unavailable",
		offline_text: "The connection to the server is not working. The app is trying to restore it&hellip;",

		_loc_too_many_waiting_tables: "There are already too many tables",
		_loc_invalid_players_number: "Invalid players number",
		_loc_invalid_rounds_number: "Invalid rounds number",
		_loc_table_unavailable: "Table unavailable",
		_loc_already_at_table: "You have already joined a table",
		_loc_you_are_not_at_table: "You are not at this table",
		_loc_$1_took_pile: "<b>$1</b> took the pile",
		_loc_$1_took_stock: "<b>$1</b> picked a card from stock",
		_loc_you_took_$1_from_stock: "You picked $1 from stock",
		_loc_not_your_card: "You don't own this card",
		_loc_cannot_drop_card_just_picked: "You cannot drop the card taken from the stock",
		_loc_$1_dropped_$2: "<b>$1</b> has dropped $2",
		_loc_not_your_turn: "Not your turn",
		_loc_your_turn: "It's your turn!",
		_loc_$1_new_game_with_$2: "<b>$1</b> has opened a game with $2",
		_loc_$1_added_$2_to_game_$3: "<b>$1</b> added $2 to game #$3",
		_loc_$1_took_pot_on_fly: "<b>$1</b> has taken his pot in-flight",
		_loc_$1_took_pot: "<b>$1</b> has taken his pot",
		_loc_at_least_three_cards_in_game: "A new game needs at least 3 cards",
		_loc_too_many_cards_in_game: "Too many cards for a game",
		_loc_cannot_flying_end: "You cannot end in-flight",
		_loc_not_your_cards: "You don't have this cards",
		_loc_combination_already_exists: "A game for this combination exists already",
		_loc_not_a_valid_game: "Not a valid game",
		_loc_add_game_error: "Add game error (000)",
		_loc_unknown_error: "Unknown error",
		_loc_too_many_wildcards: "Too many wildcards",
		_loc_invalid_combination: "Invalid combination",
		_loc_invalid_sequence_1: "Invalid sequence (1)",
		_loc_invalid_sequence_2: "Invalid sequence (2)",
		_loc_invalid_sequence_3: "Invalid sequence (3)",
		_loc_cannot_end_without_burraco: "You cannot end without at least one Burraco",
		_loc_cannot_end_with_wildcard: "You cannot end by dropping a wildcard",
		_loc_your_first_burraco: "You have your first Burraco",
	},

	it: {
		not_a_game: "Non è un gioco valido",
		not_your_cards: "Non sono le tue carte",
		not_your_card: "Non è una tua carta",
		not_your_game: "Non è il tuo gioco",
		double_combi: "Non puoi creare una seconda combinazione delle stesso valore",
		must_close_by_dropping: "Devi chiudere scartando",
		cannot_close_by_dropping_jolly: "Non puoi chiudere scartando una matta",
		cannot_close_by_dropping_locked: "Non puoi chiudere scartando l'unica carta presa dagli scarti",
		cannot_close_without_any_burraco: "Per chiudere devi avere almeno un Burraco",
		locked_card: "Non puoi scartare l'unica carta presa dagli scarti",
		too_many_waiting_tables: "Ci sono già molti tavoli in attesa",
		table_not_exists: "Il tavolo non esiste",
		seat_already_taken: "Posto già occupato",
		not_your_invite: "Non è un invito valido",
		drop_one_card: "Devi scartare solo una carta",
		choose_3_cards_for_new_game: "Un nuovo gioco richiede almeno 3 carte",
		choose_1_card_for_add_game: "Seleziona almeno una carta da aggiungere al gioco",

		home: "Home",
		login: "Accedi",
		register: "Registrati",
		profile: "Profilo",
		logout: "Esci",
		chat: "Chat",
		tables: "Tavoli",
		table: "Tavolo",
		forum: "Forum",
		forum_bug: "Segnala un problema",
		about_us: "Chi siamo",
		user: "Utente",
		users: "Utenti",
		score: "Punteggio",
		players: "Giocatori",
		ranking: "Classifica",
		username: "Nome utente",
		password: "Password",
		general: "Generale",
		wait: "…",
		save: "Salva",
		change: "Modifica",
		create_table: "Crea tavolo",
		north: "Nord",
		east: "Est",
		south: "Sud",
		west: "Ovest",
		cancel: "Annulla",
		leave: "Lascia",
		home_title: "Burraco2.com",
		website: "Sito web",
		online_players: "Giocatori Online",
		settings: "Impostazioni",
		nobody: "Nessuno",
		nobody_online: "Nessun altro utente online",
		close: "Chiudi",

		home_notifications: "Ricevi le notifiche di gioco per rimanere in contatto!",
		home_notify: "Notifiche",

		register_email: "Il tuo indirizzo e-mail",
		register_password_confirm: "Conferma Password",
		register_conditions: "Cliccando su \"Registrati\" dichiari di aver letto e accettato <a href=\"https://www.burraco2.com/regolamento-burraco-online.php\" target=\"_system\">i termini e le condizioni del servizio</a> e <a href=\"https://www.burraco2.com/privacy-policy.php\" target=\"_system\">il trattamento dei dati personali</a>.",
		register_dem: "Acconsento al trattamento dei dati per l'invio di comunicazioni commerciali e per le altre finalità elencate nell'informativa.",
		register_success: "Ti abbiamo inviato la mail per confermare l'account",

		session_login: "Accedi",
		session_forgot: "Password dimenticata?",
		session_invalid: "Sessione non valida, fai il login",

		recovery_password: "Recupero password",
		recovery_recover: "Recupera",
		email_address: "Indirizzo e-mail",
		recovery_confirm: "Ti abbiamo inviato una mail con le istruzioni",

		users_one: "1 utente online",
		users_many: "%1 utenti online",
		users_record: "Partite %1 (%3) — Vittorie %2 (%4)",
		users__only_user: "Al momento sei l'unico giocatore online.",

		profile_registration_date: "Data registrazione",
		profile_last_login: "Ultimo accesso",
		profile_forum_profile: "Modifica profilo completo",
		profile_settings: "Impostazioni",
		profile_records: "Statistiche",
		profile_matches: "Partite",
		profile_buddies: "Amici",
		profile_name: "Nome visualizzato",
		profile_language: "Lingua",
		profile_newsletter: "Voglio ricevere la newsletter e le notifiche di gioco.",
		profile_dem: "Acconsento al trattamento dei dati per l'invio di comunicazioni commerciali e per le altre finalità elencate nell'informativa.",
		profile_h_last_matches: "Ultime partite giocate",
		profile_h_change_password: "Modifica password",
		profile_current_password: "Password attuale",
		profile_password_dont_match: "La nuova password e la conferma non coincidono",
		profile_password_changed: "La nuova password è stata impostata",
		profile_settings_changed: "Le impostazioni sono state salvate",
		profile__match_points: "%1 Punti — %2 Punti vittoria",
		profile_no_buddies: "Non ha ancora nessun amico",

		create_players: "Numero di giocatori",
		create_players_4: "4 Giocatori",
		create_players_2: "2 Giocatori",
		create_rounds: "Numero di mani",
		create_sit_down: "Siedi qui",
		create_your_self: "[Tu stesso]",
		create_free: "[Libero]",
		create_duplicate_player: "Lo stesso giocatore occupa due posti",
		create_self_not_present: "Scegli dove sederti",
		create_runs: "Smazzate",

		tables_rounds: "Smazzate",
		tables__no_tables: "Non ci sono tavoli in attesa. Usa l'icona + in alto a destra per crearne uno.",

		stats_h_beginning: "Statistica dall'inizio",
		stats_h_30days: "Statistica ultimi 30 giorni",
		stats_matches: "Partite",
		stats_wins: "Vittorie",
		stats_losses: "Sconfitte",
		stats_draws: "Pareggi",
		stats_leaves: "Abbandoni",
		stats_timeouts: "Tempo Scaduto",
		stats_points : "Punti",

		digest_timeout: "Tempo scaduto",
		digest_leave: "Abbandono",
		digest_draw: "Pareggio",
		digest_red: "Vittoria Rosso",
		digest_blue: "Vittoria Blu",

		table_sort_suit: "Seme",
		table_sort_value: "Valore",
		table_takepile: "Scarti",
		table_takestock: "Pesca",
		table_drop: "Scarta",
		table_points: "punti",
		table_cards: "carte",
		table_games: "Giochi",
		table_new_game: "Nuovo gioco",
		table_pile: "Pila degli Scarti",
		table_stock: "Tallone",
		table_pot: "Pozzetto",
		table_pots: "Pozzetti",
		table_tab_all: "Tutto",
		table_no_table: "Nessun tavolo disponibile",
		table_new_table: "Crea tavolo",
		table_options: "Opzioni partita",
		table_invite: "Invita amici",
		table_add: "Aggiungi",
		table_burraco: "Burraco",

		table_wait: "In attesa dei giocatori",
		table_wait__free: "Posto libero",
		table_wait__invited: "In attesa di conferma",

		table_chat: "Chat del tavolo",

		table_status: "Punteggio del Tavolo",
		table_status_round: "Mano %1 di %2",
		table_status_running: "Il gioco è in corso…",
		table_status_base: "Base",
		table_status_positive: "Positivi",
		table_status_negative: "Negativi",
		table_status_total: "Totale",
		table_result_grandtotal: "Somma Totale",

		table_users: "Giocatori del Tavolo",
		table_hurry: "Sbrigati! Hai a disposizione ancora un minuto",

		table_result: "Punteggio",
		table_result_back_to_table: "Ritorna al Tavolo",
		table_result_leave_table: "Lascia il tavolo",
		table_result_wait_for_players: "In attesa degli altri giocatori",
		table_result_points: "%1 punti",

		table_leave: "Lascia il tavolo",
		table_leave_advice: "Se abbandoni perderai la partita e sarai penalizzato in classifica. Procedi?",

		table_thankyou: "La partita è terminata, grazie per aver giocato ❤",

		report_round: "Smazzata",
		report_of: "di",
		report_title: "Report dei punti",
		report_points_base: "Punti base",
		report_points_positive: "Punti positivi",
		report_points_game: "Punti partita",
		report_points_negative: "Punti negativi",
		report_points: "Totale Punti",

		log_discard: "%1 ha scartato %2%3",
		log_takestock: "%1 ha pescato una carta",
		log_takepile: "%1 ha preso gli scarti %2",
		log_gamenew: "%1 ha creato un nuovo gioco con %2 carte",
		log_gameadd: "%1 ha attaccato %2 carte ad un gioco",
		log_takepot: "%1 ha presto un pozzetto",

		rank_none: "Classifica non disponibile",
		match_detail: "Dettaglio partita",
		match_none: "Nessuna partita recente",

		home_welcome: "Bentornato",
		home_goto_tables: "Vai ai Tavoli",
		home_goto_chat: "Vai alla Chat",
		home_your_profile: "Il tuo Profilo",

		buddies_none: "Non hai amici.<br/>Clicca sul cuore vicino al nome di un giocatore per inserirlo tra gli amici.",

		offline: "Connessione assente",
		offline_text: "In questo momento la connessione al server non è funzionante. L'app sta cercando di ricollegarsi&hellip;",

		_loc_too_many_waiting_tables: "Ci sono troppi tavoli in attesa",
		_loc_invalid_players_number: "Numero di giocatori non valido",
		_loc_invalid_rounds_number: "Numero di mani non valido",
		_loc_table_unavailable: "Tavolo non disponibile",
		_loc_already_at_table: "Sei già ad un tavolo",
		_loc_you_are_not_at_table: "Non sei ad un tavolo",
		_loc_$1_took_pile: "<b>$1</b> ha raccolto gli scarti",
		_loc_$1_took_stock: "<b>$1</b> ha pescato dal tallone",
		_loc_you_took_$1_from_stock: "Hai pescato $1 dal tallone",
		_loc_not_your_card: "Non possiedi questa carta",
		_loc_cannot_drop_card_just_picked: "Non puoi scartare la carta appena pescata",
		_loc_$1_dropped_$2: "<b>$1</b> ha scartato $2",
		_loc_not_your_turn: "Non è il tuo turno",
		_loc_your_turn: "È il tuo turno!",
		_loc_$1_new_game_with_$2: "<b>$1</b> ha aperto un gioco con $2",
		_loc_$1_added_$2_to_game_$3: "<b>$1</b> ha aggiunto $2  al gioco #$3",
		_loc_$1_took_pot_on_fly: "<b>$1</b> ha preso il pozzetto al volo",
		_loc_$1_took_pot: "<b>$1</b> ha preso il pozzetto",
		_loc_at_least_three_cards_in_game: "Un gioco è composto da almeno 3 carte",
		_loc_too_many_cards_in_game: "Troppe carte per un gioco",
		_loc_cannot_flying_end: "Non puoi chiudere al volo",
		_loc_not_your_cards: "Non possiedi queste carte",
		_loc_combination_already_exists: "Non puoi creare un'altra combinazione con questo valore",
		_loc_not_a_valid_game: "Non è un gioco valido",
		_loc_add_game_error: "Add game error (000)",
		_loc_unknown_error: "Errore sconosciuto",
		_loc_too_many_wildcards: "Troppe matte",
		_loc_invalid_combination: "Combinazione non valida",
		_loc_invalid_sequence_1: "Sequenza non valida (1)",
		_loc_invalid_sequence_2: "Sequenza non valida (2)",
		_loc_invalid_sequence_3: "Sequenza non valida (3)",
		_loc_cannot_end_without_burraco: "Non puoi chiudere senza aver fatto Burraco",
		_loc_cannot_end_with_wildcard: "Non puoi chiudere con una matta",
		_loc_your_first_burraco: "Hai fatto il tuo primo Burraco",
	}
};

let Loc = {};

function LocM(lang) {
	Loc = localization[lang];
}

export { Loc, LocM };
