<template>
<aside id="nav" :class="{ active: Store.navDrawer }" @click="Store.navDrawer = false">
	<nav v-if="Store.member" >
		<h3><router-link to="/">{{ Loc.home_title }}</router-link></h3>
		<router-link :to="Store.member.profileUrl" class="mdi mdi-account-circle">{{ Loc.profile }}</router-link>
		<router-link to="/settings" class="mdi mdi-tune">{{ Loc.settings }}</router-link>
		<a class="mdi" :class="(Store.settings.notification ? 'mdi-bell' : 'mdi-bell-off')" @click="toggleNotification()">{{ Loc.home_notify }}</a>
		<hr/>
		<router-link to="/tables" class="mdi mdi-cards-playing">{{ Loc.tables }}</router-link>
		<router-link to="/chat" class="mdi mdi-chat">{{ Loc.chat }}</router-link>
		<router-link to="/buddies" class="mdi mdi-heart">{{ Loc.profile_buddies }}</router-link>
		<router-link to="/members" class="mdi mdi-account-multiple">{{ Loc.online_players }}</router-link>
		<router-link to="/ranking" class="mdi mdi-podium">{{ Loc.ranking }}</router-link>
		<hr/>
		<a :href="home" target="_system" class="mdi mdi-earth">Burraco2.com <i class="mdi mdi-open-in-new"></i></a>
		<a :href="forum" target="_system" class="mdi mdi-forum">{{ Loc.forum }} <i class="mdi mdi-open-in-new"></i></a>
		<a :href="forum+'index.php?board=3.0'" target="_system" class="mdi mdi-bug">{{ Loc.forum_bug }} <i class="mdi mdi-open-in-new"></i></a>
	</nav>
</aside>
</template>

<style lang="scss">
#nav {
	&::before {
		content: "";
		position: fixed;
		background: rgba(0, 0, 0, .5);
		z-index: 100;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		visibility: hidden;
		opacity: 0;
		transition: opacity .3s, visibility 0s linear 0.5s;
	}

	nav {
		position: fixed;
		z-index: 101;
		top: 0;
		left: -16rem;
		height: 100%;
		width: 16rem;
		max-width: 80%;
		background: white;

		> a {
			display: block;
			padding: .5rem 1rem;
			&:hover,
			&.router-link-active {
				background: var(--color-light);
			}
			&::before {
				margin-right: .25rem;
			}
		}
	}

	&.active {
		nav {
			left: 0;
		}
		&::before {
			visibility: visible;
			opacity: .6;
			transition-delay: 0s;
		}
	}

	h3 {
		margin: 0 0 1rem 0;
		font-family: Ribeye;
		line-height: 3rem;
		background: var(--color-primary);
		padding: 0 1rem;
		text-align: center;
		a {
			color: white;
		}
	}
}
</style>

<script>

import { Loc } from "@/Loc.js"
import Store from "@/Store.js"

export default {
	data: () => ({
		Loc,
		Store,
		home: process.env.VUE_APP_SITE_HOME,
		forum: process.env.VUE_APP_SITE_FORUM,
	}),

	methods: {
		async toggleNotification() {
			if (Store.settings.notification) {
				Store.settings.notification = false;
				Store.saveSettings();

				let f = new FormData();
				f.append("token", Store.firebaseToken);
				f.append("status", "inactive");
				await this.Store.postCall("/user/firebase", f);

			} else {
				let res = await Notification.requestPermission();
				if (res === "denied") return;
				if (await Store.processFirebaseToken()) {
					Store.settings.notification = true;
				}
			}
			Store.saveSettings();
		},
	}
}

</script>
