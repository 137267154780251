import Store from "@/Store.js"
import Member from "@/classes/Member.js"
import ChatMessage from "@/classes/ChatMessage.js"
import Table from "@/classes/Table.js"
import { Loc } from "@/Loc.js"

const seeds = {
	h: { v: 1, l: "Heart",   i: "mdi-cards-heart",   c: "playcard-seed-red" },
	d: { v: 2, l: "Diamond", i: "mdi-cards-diamond", c: "playcard-seed-red" },
	c: { v: 3, l: "Club",    i: "mdi-cards-club",    c: "playcard-seed-black" },
	s: { v: 4, l: "Spade",   i: "mdi-cards-spade",   c: "playcard-seed-black" },
	x: { v: 5, l: "Jolly",   i: "mdi-star-circle-outline", c: "playcard-seed-red" },
	y: { v: 6, l: "Jolly",   i: "mdi-star-circle-outline", c: "playcard-seed-black" },
};

const cardLabels = {
	a: "A",
	2: "2",
	3: "3",
	4: "4",
	5: "5",
	6: "6",
	7: "7",
	8: "8",
	9: "9",
	x: "10",
	j: "J",
	q: "Q",
	k: "K",
	s: "x",
};

export default {
	ws: null,

	start(app) {

		this.app = app;

		Store.connection = "none";
		this.ws = new WebSocket(process.env.VUE_APP_SITE_WEBSOCKET, "burraco2");

		this.ws.addEventListener("open", () => {
			console.log("connected");
			this.send("login", {
				id_member: Store.member.id_member,
				token: Store.member.token,
			});
		});

		this.ws.addEventListener("message", ev => {
			const [ cmd, data ] = JSON.parse(ev.data);
			console.debug("↓", cmd, data);
			if (this[cmd+"Slot"]) {
				this[cmd+"Slot"](data);
				if (data.tableMessages) {
					data.tableMessages.forEach(a => {
						if (a.message instanceof Array) {
							a.message = Loc[a.message[0]].replace(/\$(\d)/g, (n, i) => a.message[i]);
						} else if (Loc[a.message] !== undefined) {
							a.message = Loc[a.message];
						}
						if (a.type === "warn") {
							Store.showPlayWarning(a.message.replaceAll(/#(\w)(\w)/g, (m, m1, m2) => cardLabels[m1]+'<i class="mdi '+seeds[m2].i+'"></i>'));
						} else {
							Store.showPlayNotice(a.message.replaceAll(/#(\w)(\w)/g, (m, m1, m2) => cardLabels[m1]+'<i class="mdi '+seeds[m2].i+'"></i>'));
						}
					});
				}
			} else {
				console.error(`Unknown command ${cmd}`);
			}
		});

		this.ws.addEventListener("close", () => {
			Store.connection = "offline";
			console.log("disconnected");
		});
	},

	send(cmd, data = {}) {
		console.debug("↑", cmd, data);
		this.ws.send(JSON.stringify([ cmd, data ]));
	},

	// ### Slots ###

	errorSlot(data) {
		if (data.errmsg === "invalid-session") {
			alert("Sessione non valida");
			window.location = process.env.VUE_APP_SITE_LOGIN;
			return;
		}

		if (data.errmsg instanceof Array) {
			data.errmsg = Loc[data.errmsg[0]].replace(/\$(\d)/g, (n, i) => data.errmsg[i]);
		} else if (Loc[data.errmsg] !== undefined) {
			data.errmsg = Loc[data.errmsg];
		}

		Store.showError(data.errmsg);
	},

	warningSlot(data) {
		Store.showWarning(data.errmsg);
	},

	memberLoginSlot(data) {

		// Elenco utenti
		Store.members.clear();
		data.members.forEach(a => Store.members.set(a.id_member, new Member(a)));

		// Elenco tavoli
		Store.tables.clear();
		data.tables.forEach(a => Store.tables.set(a.uuid, new Table(a)));

		if (data.table) {
			this.tableStatusSlot(data, true);
			Store.member.table = Store.tables.get(data.member.table.uuid);
			Store.member.tableSeat = data.member.table.seat;
		} else if (data.member.table) {
			Store.member.table = Store.tables.get(data.member.table.uuid);
			Store.member.tableSeat = data.member.table.seat;
			this.app.$router.push("/tables");
		} else if (this.app.$route.path.indexOf("/table") > -1 && this.app.$route.path !== "/tables") {
			this.app.$router.push("/tables");
		}

		Store.connection = "online";
		console.log("logged in");

	},

	loginSlot(data) {
		Store.members.set(data.member.id_member, new Member(data.member));
	},

	logoutSlot(data) {
		Store.members.delete(data.id_member);
	},

	chatSlot(data) {
		Store.chatMessages.push(new ChatMessage(data));
		Store.chatMessages = Store.chatMessages.slice(-100);
	},

	tableNewSlot(data) {
		let table = new Table(data);

		if (!Store.member.table) {
			table.members.forEach(a => {
				if (a && a.id_member === Store.member.id_member) {
					Store.member.table = table;
					Store.showNotice("Sei stato invitato al tavolo");
				}
			});
		}

		Store.tables.set(data.uuid, table);
	},

	tableUpdateSlot(data) {
		let table = Store.tables.get(data.uuid);
		table.updateMembers(data.members);

		if (!Store.member.table) {
			// Se non sono ad alcun tavolo vedo se sto partecipando a questo
			table.members.forEach((a, i) => {
				if (a && a.id_member === Store.member.id_member) {
					Store.member.table = table;
					Store.member.tableSeat = i;
				}
			});
		} else if (table === Store.member.table) {
			// Se è il tavolo del giocatore controllo se è ancora al tavolo
			let flag = false;
			table.members.forEach((a, i) => {
				if (a && a.id_member === Store.member.id_member) {
					flag = true;
					Store.member.tableSeat = i;
				}
			});
			if (!flag) Store.member.table = null;
		}
	},

	tableRemoveSlot(data) {
		Store.tables.delete(data.uuid);
	},

	tableStatusSlot(data, forceNew = false) {

		if (data.members) data.members.forEach((m, i) => {
			Store.members.get(m.id_member).updateData(m);
			if (Store.member.tableSeat === null && m.id_member == Store.member.id_member) Store.member.tableSeat = i;
		});

		if (Store.table && !forceNew) {
			Store.table.updateData(data.table);
		} else {
			Store.table = new Table(data.table);
		}

		this.app.$router.push("/table");
	},

};
