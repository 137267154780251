<template>
<div class="headerBar">
	<div v-if="!hideMain" class="headerBar-main">
		<a v-if="!hideHamburger" @click="Store.toggleNavDrawer" class="mdi mdi-menu"></a>
		<span v-else style="flex: 0 0 1rem"></span>
		<span class="auto">{{ title }}</span>
		<span v-for="i in actions" :key="i.icon">
			<router-link v-if="i.link" :to="i.link" :title="i.label" class="mdi" :class="i.icon"></router-link>
			<a v-else :title="i.label" class="mdi" :class="i.icon" @click="$emit(i.emit)"></a>
		</span>
	</div>
	<div class="headerBar-tabs" v-if="tabs">
		<div>
			<router-link v-for="i in tabs" :key="i.path" :to="i.path">{{ i.label }}</router-link>
		</div>
	</div>
</div>

</template>

<style lang="scss">
.headerBar {

	box-shadow: var(--shadow-1);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	font-size: 1.2rem;
	z-index: 1;

	&-main {
		background: var(--color-primary);
		color: white;
		line-height: 3rem;
		height: 3rem;
		display: flex;

		a {
			color: white;
			width: 3rem;
			height: 3rem;
			text-align: center;
			border-radius: 1rem;
			display: block;
			&:hover {
				background: var(--color-vibrant);
			}
		}

		span.auto {
			flex: auto;
		}
	}

	&-tabs {
		background-color: white;
		div {
			display: flex;
			flex-direction: row;
			line-height: 2.5rem;
			font-size: .9rem;
			justify-content: center;
			overflow: auto;
			margin: auto;
		}
		a {
			flex: none;
			padding: 0 1rem;
			color: black;
			text-transform: uppercase;
			border-style: solid;
			border-color: transparent;
			border-width: .25rem 0;
			&.router-link-exact-active,
			&:hover {
				border-bottom-color: var(--color-primary);
			}
		}
	}

}

</style>

<script>

import Store from "@/Store.js"

export default {
	props: ["title", "tabs", "actions", "hideHamburger", "hideMain"],

	data: () => ({
		Store,
	}),

	mounted() {
		Store.navDouble = (!!this.tabs && !this.hideMain);
	},
}
</script>
