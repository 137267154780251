<template>
<aside class="notifications" v-if="Store.notifications.length">
	<span v-for="i in Store.notifications" :key="i.time" :class="i.type" @click.self="Store.removeMessage(i.time)" v-html="i.text"></span>
</aside>
</template>

<style lang="scss">
.notifications {

	position: fixed;
	right: 0;
	bottom: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 1rem;

	span {
		text-align: center;
		color: white;
		padding: .5rem 1rem;
		background: rgba(0, 0, 0, .6);
		border-radius: 1rem;
		box-shadow: var(--shadow-3);
		margin: 0 1rem;
		width: calc(100vw - 4rem);
		cursor: pointer;

		&.play-notice {
			text-align: right;
			width: auto;
		}

		&.play-warn {
			text-align: right;
			background: rgba(227, 173, 0, .8);
			color: black;
			width: auto;
		}

		&.warn {
			background: rgba(227, 173, 0, .8);
			color: black;
		}

		&.error {
			background: rgba(200, 0, 0, 8);
		}

	}

}
</style>

<script>

import Store from "@/Store.js"

export default {
	data: () => ({
		Store,
	}),
}
</script>
