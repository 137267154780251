class Member {
	constructor(d = {}) {
		Object.assign(this, {
			id_member: null,
			name: "",
			table: null,
			tableSeat: null,
			info: null,
			buddies: [],
		}, d);

		this.profileUrl = "/profile/" + d.id_member;
		this.avatarUrl = "https://www.burraco2.com/avatar.php?u=" + d.id_member;

		this.buddies = this.buddies.map(i => new Member(i));

	}

	updateData(d = {}) {
		if (d.table) this.table = d.table;
		if (d.info) this.info = d.info;
		if (d.buddies) this.buddies = d.buddies;
	}

	static minimal(id_member, name) {
		return new Member({ id_member, name });
	}
}

export default Member;
