const cardLabels = {
	a: "A",
	2: "2",
	3: "3",
	4: "4",
	5: "5",
	6: "6",
	7: "7",
	8: "8",
	9: "9",
	x: "10",
	j: "J",
	q: "Q",
	k: "K",
	s: "x",
};

const cardOrder = {
	'a': 14,
	'k': 13,
	'q': 12,
	'j': 11,
	'x': 10,
	'9': 9,
	'8': 8,
	'7': 7,
	'6': 6,
	'5': 5,
	'4': 4,
	'3': 3,
	'2': 2,
	's': 0,
};

const seeds = {
	h: { v: 1, l: "Heart",   i: "mdi-cards-heart",   c: "playcard-seed-red" },
	d: { v: 2, l: "Diamond", i: "mdi-cards-diamond", c: "playcard-seed-red" },
	c: { v: 3, l: "Club",    i: "mdi-cards-club",    c: "playcard-seed-black" },
	s: { v: 4, l: "Spade",   i: "mdi-cards-spade",   c: "playcard-seed-black" },
	x: { v: 5, l: "Jolly",   i: "mdi-star-circle-outline", c: "playcard-seed-red" },
	y: { v: 6, l: "Jolly",   i: "mdi-star-circle-outline", c: "playcard-seed-black" },
};

const backs = {
	b: { c: "#0057ae" },
	r: { c: "#e20800" },
};

class Card {
	constructor(card) {
		if (card.length === 3) {
			Object.assign(this, {
				f: card,
				s: card[0], // Seme
				S: seeds[card[0]], // Seme
				v: card[1], // Valore
				V: cardOrder[card[1]],
				l: cardLabels[card[1]],
				b: card[2], // Colore del retro
				B: backs[card[2]],
				p: false, // picked
				j: (card[1] === "2" || card[1] === "s"), // Matta
			});
		} else {
			Object.assign(this, {
				b: card, // Colore del retro
				B: backs[card],
			});
		}
	}

	toJSON() {
		return this.f;
	}

}

export default Card;
