<template>
<section class="wrap">
	<HeaderBar :title="Loc.home_title"/>
	<article>
		<p class="text-center">
			{{ Loc.home_welcome }}
			<router-link :to="Store.member.profileUrl">{{ Store.member.name }}</router-link>
			<br/>
			<img :src="Store.member.avatarUrl" class="avatar-regular"/>
		</p>
		<p class="text-center">
			<router-link to="/tables" class="submit mdi mdi-cards-playing">
				{{ Loc.home_goto_tables }}
			</router-link>
		</p>
		<p class="text-center">
			<router-link to="/chat" class="button mdi mdi-chat">
				{{ Loc.home_goto_chat }}
			</router-link>
		</p>
		<p class="text-center">
			<router-link :to="Store.member.profileUrl" class="button mdi mdi-account-circle">
				{{ Loc.home_your_profile }}
			</router-link>
		</p>
	</article>
</section>
</template>

<script>

import { Loc } from "@/Loc.js"
import Store from "@/Store.js"

import HeaderBar from '@/components/HeaderBar.vue'

export default {
	components: { HeaderBar },

	data: () => ({
		Loc,
		Store,
	}),
}
</script>
