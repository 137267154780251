<template>
	<NavigationDrawer/>
	<main v-if="Store.connection == 'online'" :class="{ navDouble: Store.navDouble }">
		<router-view/>
	</main>
	<aside id="splash" v-else-if="Store.connection == 'none'">
		<div>
			<span style="font:2rem Ribeye">Burraco2.com</span>
			<br/>
			<i class="mdi mdi-loading mdi-spin"></i>
		</div>
	</aside>
	<aside id="offline" v-else-if="Store.connection == 'offline'">
		<div>
			<p>
				<i class="mdi mdi-broadcast-off" style="font-size:3rem"></i>
				<br/>
				Server non raggiungibile
			</p>
			<p>
				<a @click="reconnect">Riconnetti</a>
			</p>
		</div>
	</aside>
	<aside v-if="notificationOverlay" class="overlay">
		<div style="display:flex;flex-direction:column;height:50vh">
			<p class="text-center">{{ Loc.home_notifications }}</p>
			<p class="text-center" style="flex:auto">
				<a @click="confirmNotificationOverlay()" class="mdi mdi-bell-off button" style="font-size:6rem"></a>
			</p>
			<p class="text-center">
				<a class="button" @click="cancelNotificationOverlay()">{{ Loc.close }}</a>
			</p>
		</div>
	</aside>
	<NotificationBar/>
</template>

<style lang="scss">

@font-face {
	font-family: "Ribeye";
	src: url("fonts/Ribeye-Regular.ttf"), url("fonts/ribeye-regular.eot"), url("fonts/ribeye-regular.woff") format("woff");
}

@font-face {
	font-family: "ProximaNova";
	src: url("fonts/proxima_nova.ttf");
}

@import '~@mdi/font/css/materialdesignicons.css';

:root {
	--color-primary: #006e29;
	--color-vibrant: #37a42c;
	--color-light: #d8e8c2;

	--color-gray: #aaa;
	--color-silver: #eee;
	--color-red: #f00;
	--color-red-light: #fdd;
	--color-blue: #00f;
	--color-blue-light: #ddf;

	--shadow-1: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	--shadow-2: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	--shadow-3: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

* {
	transition: all .3s;
	&:focus {
		outline-style: none;
	}
	scrollbar-width: thin;
	scrollbar-color: var(--color-primary) transparent;
}


html,
body {
	font-family: ProximaNova, sans-serif;
	font-size: 16px;
	padding: 0;
	margin: 0;
	color: #333;
	background: var(--color-silver);
	height: 100%;
}

html,
#app {
	height: 100%;
	overflow: auto;
}

a {
	cursor: pointer;
	text-decoration: none;
	color: var(--color-primary);
	&:hover {
		color: var(--color-vibrant);
	}
	&.mdi {
		&::before {
			padding-right: .25rem;
		}
	}
}

a.button,
a.submit,
button {
	background-color: white;
	color: var(--color-primary);
	display: inline-block;
	padding: .5rem 1rem;
	border-radius: .25rem;
	border: none;
	min-width: 8rem;
	box-shadow: var(--shadow-1);
	cursor: pointer;
	font-size: 1rem;
	font-family: inherit;
	box-sizing: content-box;
	&:hover {
		color: var(--color-vibrant);
		box-shadow: var(--shadow-2);
	}
	&.mdi::before {
		padding-right: .25rem;
	}
}

a.submit,
button[type=submit] {
	background-color: var(--color-primary);
	color: white;
	font-weight: bold;
	&:hover {
		background-color: var(--color-vibrant);
		color: white;
	}
}

.text {
	&-center {
		text-align: center;
	}

	&-big {
		font-size: 1.6rem;
		font-weight: bold;
	}

	&-warning {
		font-style: italic;
	}
}


.color-primary {
	color: var(--color-primary);
}

#splash,
#offline {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(to bottom, var(--color-silver), var(--color-light));
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

main {
	padding: 3rem 0 0 0;
	height: 100%;
	box-sizing: border-box;
	transition: margin 0s;
	&.navDouble {
		padding: 6rem 0 0 0;
	}
}

hr {
	margin: 1rem 0;
	border: none;
	border-top: 1px solid var(--color-light);
}

/* Component container */
section {
	overflow: auto;
	margin: auto;
	&.wrap {
		max-width: 60rem;
	}
}

article,
.card {
	background: white;
	margin: 1rem;
	box-shadow: var(--shadow-1);
	border-radius: .25rem;
	padding: 0 1rem;
	overflow: auto;
}

small {
	font-size: .75rem;
}

.avatar {

	&-small {
		width: 4rem;
		height: 4rem;
		display: block;
		object-fit: contain;
		background: white;
	}

	&-tiny {
		width: 3rem;
		height: 3rem;
		display: block;
		object-fit: contain;
		background: white;
	}

	&-regular {
		width: 6rem;
		height: 6rem;
		display: inline-block;
		object-fit: contain;
		background: white;
	}

	&-shadow {
		box-shadow: var(--shadow-1);
		&:hover {
			box-shadow: var(--shadow-2);
		}
	}

	&-rounded {
		border-radius: .5rem;
	}

}

.flex-auto {
	flex: auto;
}

.mdi {
	&-5x {
		font-size: 5rem;
	}
	&-margin-right {
		margin-right: .25rem;
	}
	&-margin-left {
		margin-left: .25rem;
	}
}

.narrow {
	width: 20rem;
	margin: auto;
}

form {
	.tight {
		max-width: 20rem;
		margin: auto;
	}

	input[type=text],
	input[type=password],
	input[type=email],
	select {
		display: block;
		border-style: solid;
		border-color: var(--color-gray);
		border-width: 0 0 1px 0;
		border-radius: 0;
		background: white;
		font-family: inherit;
		font-size: inherit;
		padding: .5rem 0;
		color: inherit;
		width: 100%;
		&:focus {
			border-color: var(--color-vibrant);
			& + span {
				color: var(--color-vibrant);
			}
		}
	}

	label.material {
		display: flex;
		flex-direction: column-reverse;
		span {
			font-size: .85rem;
		}
	}
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, .5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;
	> div {
		width: 50vw;
		height: 75vh;
		background: white;
		border-radius: .25rem;
		box-sizing: border-box;
		padding: 0 1rem;
	}
}

.chat {

	height: 100%;
	display: flex;
	flex-direction: column;

	&-feed {
		flex: auto;
	}

	&-form {
		flex: none;
		margin: 0 1rem 1rem 1rem;

		form {
			display: flex;
			padding: .5rem 0;
		}

		input {
			flex: auto;
		}

		button[type="submit"] {
			flex: none;
			min-width: 3rem;
			margin-left: 1rem;
		}
	}

	&-row {
		display: flex;
		margin: 1rem 0;
		gap: 1rem;

		> a {
			display: block;
			width: 3rem;
			height: 3rem;
			background-size: cover;
			background-position: center;
			box-shadow: var(--shadow-1);
			border-radius: .25rem;
		}

		> div {
			flex: auto;
		}
	}
}

.report {
	&-seats {
		display: flex;
		justify-content: space-around;
		margin: 1rem 0;
		i {
			line-height: 4rem;
			font-size: 3rem;
		}
		span {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 0 .25rem;
			box-sizing: border-box;
			background: rgba(0, 0, 0, .5);
			color: white;
			text-align: center;
		}
	}

	&-seat {
		border-style: solid;
		border-width: 0 0 .25rem 0;
		text-align: center;
	}

	&-row {
		display: flex;
		align-items: center;
		gap: 1rem;
	}

	&-placeholder {
		width: 4rem;
		height: 4rem;
		line-height: 4rem;
		text-align: center;
		font-size: 4rem;
		color: var(--color-gray);
	}

	&-stats {
		> div {
			display: flex;
			margin: 1rem 0;
		}
	}
}

.playcard {
	border: 1px solid #666;
	background: white;
	width: 1.5rem;
	height: 2.5rem;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	text-align: center;
	border-radius: .25rem;
	position: relative;
	cursor: pointer;
	top: 0;

	&-back {
		background: #ccc;
	}

	&-seed-red {
		color: #e20800;
	}

	&-red {
		border-left: .25rem solid var(--color-red);
		&.playcard-current {
			background: var(--color-red-light);
		}
	}

	&-blue {
		border-left: .25rem solid var(--color-blue);
		&.playcard-current {
			background: var(--color-blue-light);
		}
	}
}


</style>

<script>

import Store from "@/Store.js"
import { Loc } from "@/Loc.js"
import Com from "@/Com.js"
import Member from "@/classes/Member.js"

import NavigationDrawer from "@/components/NavigationDrawer.vue"
import NotificationBar from "@/components/NotificationBar.vue"

export default {
	components: { NavigationDrawer, NotificationBar },

	data: () => ({
		Store,
		Loc,
		firebase: null,
		notificationOverlay: false,
	}),

	async mounted() {

		try {
			let res = await this.Store.getCall("/user");
			Store.member = new Member(res.response);
			await Com.start(this);
		} catch (e) {
			if (e.message === "not-logged-in") {
				alert("Devi accedere per giocare");
				window.location = process.env.VUE_APP_SITE_LOGIN;
			}
		}

		if (Notification.permission === "granted") {
			if (await Store.processFirebaseToken()) {
				if (Store.settings.notification !== true) {
					Store.settings.notification = true;
					Store.saveSettings();
				}
			}
		} else if (Store.settings.notification === null) {
			this.notificationOverlay = true;
		}
	},

	methods: {
		reconnect() {
			Com.start(this);
		},

		async confirmNotificationOverlay() {
			this.notificationOverlay = false;
			let res = await Notification.requestPermission();
			if (res === "denied") {
				Store.settings.notification = false;
			} else {
				if (await Store.processFirebaseToken()) {
					Store.settings.notification = true;
				}
			}
			Store.saveSettings();
		},

		cancelNotificationOverlay() {
			this.notificationOverlay = false;
			Store.settings.notification = false;
			Store.saveSettings();
		},

	},
}

</script>
